import { getERC20ContractAddress } from "src/helpers/blockchain.helper";
var bigInt = require("big-integer");

function getContractCodes(contractType) {
	const compiledToken721 = require("src/contracts/Token721.json");
	const compiledToken1155 = null; //require("build/contracts/Token1155.json");

	const compiledToken20 = require("src/contracts/ERC20.json");
	const compiledMarketplace = require("src/contracts/Marketplace.json");

	switch (contractType) {
		case 1155:
			return compiledToken1155;
			break;
		case 20:
			return compiledToken20;
			break;
		case "marketplace":
			return compiledMarketplace;
			break;
		case "auction":
			const compileAuction = require("src/contracts/Auction.json");
			return compileAuction;
			break;
		case "eth_auction":
			const compileETHAuction = require("src/contracts/ETHAuction.json");
			return compileETHAuction;
	}

	return compiledToken721;
}

export const getValueInContractDecimals = async (amount, ERC20ContractAddress, amountIsInWei = false) => {
	const web3 = window.web3;

	let decimals = 18;

	if (ERC20ContractAddress !== "0x0") {
		const compiledERC20Contract = getContractCodes(20);
		const ERC20Contract = new web3.eth.Contract(compiledERC20Contract.abi, ERC20ContractAddress);

		const ERC20Decimals = await ERC20Contract.methods.decimals().call();
		if (!isNaN(ERC20Decimals) && parseInt(ERC20Decimals)) decimals = parseInt(ERC20Decimals);
	}

	return amountIsInWei
		? parseFloat(amount) / Math.pow(10, parseInt(decimals))
		: parseFloat(amount) * Math.pow(10, parseInt(decimals));
};

export const deploy = (contractType, params) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const accounts = await web3.eth.getAccounts();

		const compiledContract = getContractCodes(contractType);

		console.log("Attempting to deploy from account", accounts[0]);

		let deploy_contract = new web3.eth.Contract(compiledContract.abi);
		let payload = {
			data: compiledContract.bytecode,
			arguments:
				contractType === "eth_auction"
					? [
							params.collectionID,
							params.tokenID,
							params.startingPrice,
							params.increment,
							params.startTime,
							params.endTime,
					  ]
					: params,
		};

		let parameter = {
			from: accounts[0],
			gas: "4500000",
		};

		let result = {
			tx: "",
			address: "",
		};

		deploy_contract
			.deploy(payload)
			.send(parameter, (err, transactionHash) => {
				console.log("Transaction Hash :", transactionHash);
				result.tx = transactionHash;
			})
			.on("confirmation", () => {})
			.then(newContractInstance => {
				console.log("Deployed Contract Address : ", newContractInstance.options.address);

				result.address = newContractInstance.options.address;

				resolve(result);
			})
			.catch(error => reject(error));
	});

export const mint = (contractType, contractAddress, tokenData) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			if (tokenData.send_to_address) {
				contract.methods
					.mint(tokenData.sku, tokenData.metadata_url, tokenData.send_to_address)
					.send({ from: accounts[0] })
					.once("receipt", receipt => {
						console.log(receipt);

						const tokenID = receipt.events?.Transfer?.returnValues?.tokenId;
						resolve(tokenID);
					})
					.catch(e => {
						reject(e);
					});
			} else {
				contract.methods
					.mint(tokenData.sku, tokenData.metadata_url)
					.send({ from: accounts[0] })
					.once("receipt", receipt => {
						console.log(receipt);

						const tokenID = receipt.events?.Transfer?.returnValues?.tokenId;
						resolve(tokenID);
					})
					.catch(e => {
						reject(e);
					});
			}
		} catch (e) {
			reject(e);
		}
	});

export const seekTokenIDBySku = (contractType, contractAddress, sku) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			const tokenID = await contract.methods.tokenBySKU(sku).call({
				from: accounts[0],
			});
			resolve(tokenID);
		} catch (e) {
			reject(e);
		}
	});

export const burn = (contractType, contractAddress, tokenID) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			contract.methods
				.burn(tokenID)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					console.log(receipt);

					const tokenID = receipt.events?.Transfer?.returnValues?.tokenId;
					resolve(tokenID);
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const loadAllTokens = (contractType, contractAddress) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			const tokenSupply = await contract.methods.totalSupply().call({
				from: accounts[0],
			});

			if (!tokenSupply) resolve([]);

			const tokens = [];

			const contractOwner = await contract.methods.owner().call({
				from: accounts[0],
			});

			for (let i = 0; i < tokenSupply; i++) {
				const tokenID = await contract.methods.tokenByIndex(i).call({
					from: accounts[0],
				});

				if (tokenID) {
					const tokenMetaURI = await contract.methods.tokenURI(tokenID).call({
						from: accounts[0],
					});

					const tokenOwner = await contract.methods.ownerOf(tokenID).call({
						from: accounts[0],
					});

					tokens.push({
						tokenID,
						tokenMetaHash: tokenMetaURI
							.replace("ipfs://ipfs/", "")
							.replace(process.env.REACT_APP_IPFS_BASE_URL + "ipfs/", ""),
						owner: tokenOwner,
						minter: contractOwner,
					});
				}
			}

			resolve(tokens);
		} catch (e) {
			reject(e);
		}
	});

export const markApprovedForAll = (contractType, contractAddress, marketplaceAddress) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		//check if already approved for all
		try {
			const approved = await contract.methods.isApprovedForAll(accounts[0], marketplaceAddress).call({
				from: accounts[0],
			});

			if (approved) {
				resolve();
				return;
			}
		} catch (e) {
			console.log("error", e);
		}

		try {
			contract.methods
				.setApprovalForAll(marketplaceAddress, true)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					console.log(receipt);

					resolve();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const markApproved = (contractType, contractAddress, tokenID, operatorAddress) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		//check if already approved for all
		try {
			const approvedAddress = await contract.methods.getApproved(tokenID).call({
				from: accounts[0],
			});

			if (approvedAddress === operatorAddress) {
				resolve();
				return;
			}
		} catch (e) {
			console.log("error", e);
		}

		try {
			contract.methods
				.approve(operatorAddress, tokenID)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					console.log(receipt);

					resolve();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const sellToken = ({
	contractType,
	contractAddress,
	tokenID,
	amount,
	currency,
	royaltyPercentage,
	marketplaceAddress,
	networkID,
}) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("marketplace");

		const ERC20ContractAddress = getERC20ContractAddress(currency, networkID);
		if (!ERC20ContractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new web3.eth.Contract(compiledContract.abi, marketplaceAddress);

		const accounts = await web3.eth.getAccounts();

		//const amountInWei = web3.utils.toWei(amount.toString(), "ether");
		const amountInTokenDecimals = await getValueInContractDecimals(amount, ERC20ContractAddress);

		try {
			contract.methods
				.sellERC721NFT(
					contractAddress,
					tokenID,
					bigInt(amountInTokenDecimals).toString(),
					Math.round(parseFloat(royaltyPercentage) * 100000000).toString(),
					ERC20ContractAddress
				)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					if (receipt.transactionHash) resolve(receipt.transactionHash);
					else reject();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const buyToken = ({
	contractType,
	contractAddress,
	tokenID,
	amount,
	currency,
	royaltyPercentage,
	marketplaceAddress,
	networkID,
}) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("marketplace");

		const ERC20ContractAddress = getERC20ContractAddress(currency, networkID);
		if (!ERC20ContractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new web3.eth.Contract(compiledContract.abi, marketplaceAddress);

		const accounts = await web3.eth.getAccounts();

		//const amountInWei = web3.utils.toWei(amount.toString(), "ether");
		const amountInTokenDecimals = await getValueInContractDecimals(amount, ERC20ContractAddress);

		try {
			contract.methods
				.buyERC721NFT(contractAddress, tokenID, amountInTokenDecimals.toString(), 0, ERC20ContractAddress)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					if (receipt.transactionHash) resolve(receipt.transactionHash);
					else reject();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const transferToken = ({ contractType, contractAddress, tokenID, trasferToWalletAddress }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			contract.methods
				.safeTransferFrom(accounts[0], trasferToWalletAddress, tokenID)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					console.log(receipt);

					const tokenID = receipt.events?.Transfer?.returnValues?.tokenId;
					resolve(tokenID);
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const gotSufficientBalance = (amountRequired, currency, networkID) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(20);
		const contractAddress = getERC20ContractAddress(currency, networkID);

		if (!contractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		let walletBalance = 0;
		const accounts = await web3.eth.getAccounts();

		try {
			if (contractAddress === "0x0") {
				walletBalance = await web3.eth.getBalance(accounts[0]);
			} else {
				const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);
				walletBalance = await contract.methods.balanceOf(accounts[0]).call();
			}
		} catch (e) {
			reject(e);
		}

		const balanceInEther = await getValueInContractDecimals(walletBalance, contractAddress, true);
		console.log(walletBalance, balanceInEther, amountRequired);
		if (balanceInEther && balanceInEther > amountRequired) {
			resolve(true);
		} else {
			resolve(false);
		}
	});

export const approveFunds = (payableTo, amount, currency, networkID) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(20);
		const contractAddress = getERC20ContractAddress(currency, networkID);

		if (!contractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new web3.eth.Contract(compiledContract.abi, contractAddress);

		const accounts = await web3.eth.getAccounts();

		let decimals = await contract.methods.decimals().call();
		if (!decimals) decimals = 18;

		const amountInTokenDecimals = parseFloat(amount) * Math.pow(10, parseInt(decimals));

		// check if this is already approved
		const approvedFunds = await contract.methods.allowance(accounts[0], payableTo).call();
		if (approvedFunds && parseInt(approvedFunds) >= amountInTokenDecimals) {
			resolve(true);
			return;
		}

		contract.methods
			.approve(payableTo, amountInTokenDecimals.toString())
			.send({ from: accounts[0] })
			.then(function (data) {
				if (data.transactionHash) resolve(true);
				else resolve(false);
			})
			.catch(e => {
				reject(e);
			});
	});

export const updateMarketplaceFee = (marketplaceAddress, percentage) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("marketplace");
		const contract = new web3.eth.Contract(compiledContract.abi, marketplaceAddress);

		const accounts = await web3.eth.getAccounts();

		contract.methods
			.setFeePercentage(Math.round(parseFloat(percentage) * 100))
			.send({ from: accounts[0] })
			.then(function (data) {
				console.log(data);
				if (data.transactionHash) resolve(true);
				else resolve(false);
			})
			.catch(e => {
				reject(e);
			});
	});

export const placeBid = ({ auctionAddress, amount, currency, networkID }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (auctionAddress === "demo") {
			resolve("demohash");
			return;
		}

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const ERC20ContractAddress = getERC20ContractAddress(currency, networkID);
		const contractType = ERC20ContractAddress === "0x0" ? "eth_auction" : "auction";

		const compiledContract = getContractCodes(contractType);

		if (!ERC20ContractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new web3.eth.Contract(compiledContract.abi, auctionAddress);

		const accounts = await web3.eth.getAccounts();

		const amountInTokenDecimals = await getValueInContractDecimals(amount, ERC20ContractAddress);

		try {
			if (contractType === "eth_auction") {
				// check if user has previously bidded
				const previousBid = await contract.methods.fundsByBidder(accounts[0]).call();
				let value = amountInTokenDecimals;
				if (bigInt(previousBid).gt(0)) {
					value = amountInTokenDecimals - previousBid;
				}
				contract.methods
					.bid()
					.send({ value, from: accounts[0] })
					.once("receipt", receipt => {
						if (receipt.transactionHash) resolve(receipt.transactionHash);
						else reject();
					})
					.catch(e => {
						reject(e);
					});
			} else {
				contract.methods
					.bid(amountInTokenDecimals.toString())
					.send({ from: accounts[0] })
					.once("receipt", receipt => {
						if (receipt.transactionHash) resolve(receipt.transactionHash);
						else reject();
					})
					.catch(e => {
						reject(e);
					});
			}
		} catch (e) {
			reject(e);
		}
	});

export const pickAuctionWinner = ({ auctionAddress }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("auction");

		const contract = new web3.eth.Contract(compiledContract.abi, auctionAddress);

		const winnerAddress = await contract.methods.highestBidder().call();

		const accounts = await web3.eth.getAccounts();

		try {
			contract.methods
				.auctionEnd()
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					if (receipt.transactionHash)
						resolve({
							transactionHash: receipt.transactionHash,
							winnerAddress,
						});
					else reject();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const cancelAuction = ({ auctionAddress, isWithNativeCurrency }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const contractType = isWithNativeCurrency ? "eth_auction" : "auction";

		const compiledContract = getContractCodes(contractType);

		const contract = new web3.eth.Contract(compiledContract.abi, auctionAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			contract.methods
				.cancelAuction()
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					if (receipt.transactionHash)
						resolve({
							transactionHash: receipt.transactionHash,
						});
					else reject();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});

export const gotWithdrawableBalance = ({ auctionAddress }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("eth_auction");

		const contract = new web3.eth.Contract(compiledContract.abi, auctionAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			const balance = await contract.methods.fundsByBidder(accounts[0]).call();
			if (bigInt(balance).gt(0)) resolve(true);
			else reject("No withdrawable balance found");
		} catch (e) {
			reject(e);
		}
	});

export const withdrawBid = ({ auctionAddress }) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes("eth_auction");

		const contract = new web3.eth.Contract(compiledContract.abi, auctionAddress);

		const accounts = await web3.eth.getAccounts();

		try {
			contract.methods
				.withdraw()
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					if (receipt.transactionHash)
						resolve({
							transactionHash: receipt.transactionHash,
						});
					else reject();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});
