import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { useTokenState, useCurrencyState, useListingState } from "src/state";
import { numberFormat, round } from "src/helpers/number.helper";
import { Link } from "react-router-dom";
import SquareBlock from "src/components/frontend/SquareBlock";

import metamaskIcon from "src/themes/default/assets/img/metamask-icon.svg";
import { cachedVersion } from "src/helpers/file.helper";

const ListingItemName = styled("h2")(({ theme }) => ({
	fontSize: "18px",
	[theme.breakpoints.up("sm")]: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

const ListingItemDescription = styled("p")(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

const useStyles = makeStyles(theme => {
	return {
		listingImage: {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			verticalAlign: "bottom",
		},
		listingBlock: {
			border: "1px solid #23243e",
			borderRadius: "8px",
			background: "#16182d",
			overflow: "hidden",
			boxShadow: "0 0 1.25rem rgb(31 45 61 / 5%)",
		},
		listingItemContent: {
			padding: "15px",
		},
		listingItemPrice: {
			padding: "15px",
		},
	};
});

const ListingItem = ({ listing, withScratch, name, description, linkTo, gridOptions, showEmpty, isHighlighted, ...props }) => {
	const classes = useStyles();

	const { promised: isTokenLoading, getToken } = useTokenState(listing.collectionID);
	const { promised: isListingLoading, getListingByTokenID } = useListingState();

	const { getExRateInUSD } = useCurrencyState();

	const token = getToken(listing.collectionID, listing.tokenID);

	const tokenListing = listing.type ? listing : getListingByTokenID(listing.collectionID, listing.tokenID, true);

	if ((!token || !token.imageUrl) && !showEmpty) return null;

	return isTokenLoading || isListingLoading ? null : (
		<Grid item {...(gridOptions ? gridOptions : { xs: 12, sm: 6, lg: 4 })}>
			<Link
				to={linkTo ? linkTo : `/listing/${listing.collectionID}/${token.tokenID}`}
				style={{ color: "inherit" }}
			>
				<Box className={classes.listingBlock}>
					<SquareBlock>
						<img
							src={token ? cachedVersion(token.imageUrl) : metamaskIcon}
							className={classes.listingImage}
						/>
					</SquareBlock>
					{!isHighlighted && (
						<Box
							className={
								tokenListing && tokenListing.amount
									? classes.listingItemContent
									: classes.listingItemPrice
							}
						>
							<ListingItemName>{name ? name : token.itemName} {withScratch}</ListingItemName>
							<ListingItemDescription>
								{description ? description : `@VineArt.org`}
							</ListingItemDescription>
						</Box>
					)}
					{(isHighlighted || (tokenListing && tokenListing.amount)) && (
						<Box className={classes.listingItemPrice}>
							{isHighlighted ? (
								<>
									<ListingItemName>{name ? name : token.itemName}</ListingItemName>
									{tokenListing && (
										<p>
											({numberFormat(tokenListing.amount, 0)} {tokenListing.currency})
										</p>
									)}
								</>
							) : (
								<>
									{tokenListing.purchased ? (
										<p>Sold For</p>
									) : tokenListing.type === "auction" ? (
										<p>Bid Now</p>
									) : (
										<p>Buy Now</p>
									)}
									<p>
										{tokenListing.type === "auction" ? "Base Price" : ""}
										{numberFormat(tokenListing.amount, 0)} {tokenListing.currency}
										{` (${numberFormat(
											tokenListing.amount * getExRateInUSD(tokenListing.currency)
										)})`}
									</p>
								</>
							)}
						</Box>
					)}
				</Box>
			</Link>
		</Grid>
	);
};

export default ListingItem;
