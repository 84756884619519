import { Link, useNavigate, useLocation } from "react-router-dom";
import { AppBar, Toolbar, experimentalStyled, Button, Box, Typography } from "@material-ui/core";
import { styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../components/Logo";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { shortAccount } from "src/helpers/blockchain.helper";
import { useContext, useState } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "src/components/PopperMenu";
import { useGlobalState } from "src/state";
import useClickOutside from "src/hooks/useClickOutside";

const MainNavbar = props => {
	const [isToggled, setToggled] = useState(false);
	const toggleTrueFalse = () => setToggled(!isToggled);

	const [isActive, setIsActive] = useState({
		status: false,
		key: "",
	});

	const blockchainInfo = useContext(BlockchainContext);
	const { setRedirectToAfterConnect } = useGlobalState();
	const location = useLocation();

	const navigate = useNavigate();

	const connect = () => {
		setRedirectToAfterConnect(location.pathname);

		//if (blockchainInfo) blockchainInfo.connectToBlockchain();
		navigate("/connect-wallet");
	};

	const search = value => {
		navigate("/search/" + value);
	};

	const handleToggle = key => {
		if (isActive.key === key) {
			setIsActive({
				status: false,
			});
		} else {
			setIsActive({
				status: true,
				key,
			});
		}
	};

	const domNode = useClickOutside(() => {
		setIsActive({
			status: false,
		});
	});

	return (
		<div className="header landing">
			<div className="container">
				<div className="row">
					<div className="col-xl-12">
						<div className="navigation">
							<nav className="navbar navbar-expand-lg navbar-dark">
								<div className="brand-logo">
									<Link to="/">
										<Logo />
									</Link>
								</div>
								<div
									className={isToggled ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
								>
									<ul className="navbar-nav" ref={domNode}>
										<li className="nav-item dropdown" onClick={() => handleToggle(1)}>
											<a href="https://pbw2023.metaveblen.org/" className="nav-link">
												Home
											</a>
										</li>
										{/* <li className="nav-item dropdown" onClick={() => handleToggle(2)}>
											<a className="nav-link">Explore</a>
											<div class={isActive.key == 2 ? "dropdown-menu show" : "dropdown-menu"}>
												<Link to="/explore-grid" className="dropdown-item">
													Explore Grid
												</Link>
												<Link to="/explore-list" className="dropdown-item">
													Explore List
												</Link>
												<Link to="/explore-details" className="dropdown-item">
													Explore Details
												</Link>
											</div>
										</li> */}
										<li className="nav-item dropdown" onClick={() => handleToggle(3)}>
											<Link to="/staking" className="nav-link">
												Staking
											</Link>
										</li>
										<li
											className="nav-item dropdown position-statics"
											onClick={() => handleToggle(4)}
										>
											<a className="nav-link">About</a>
											<div
												className={
													isActive.key == 4
														? "dropdown-menu mega-menu show"
														: "dropdown-menu mega-menu"
												}
											>
												<div className="row">
													<div className="col-xl-12">
														<div className="mega-menu-nav">
															<Link to="/about#veblenian" className="dropdown-item">
																Veblenian
															</Link>
															{/* <Link to="/about#veblen" className="dropdown-item">
															Veblen Good
															</Link> */}
															<Link to="/about#governance" className="dropdown-item">
																Governance
															</Link>
															<Link to="/about#show" className="dropdown-item">
																V Show
															</Link>
															<Link to="/about#mvpay" className="dropdown-item">
																MVPay
															</Link>
															<Link to="/about#privilege" className="dropdown-item">
																Privilege
															</Link>
															<Link to="/about#journey" className="dropdown-item">
																MV Journey
															</Link>
														</div>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>

								<div className="signin-btn d-flex align-items-center">
									{blockchainInfo.account ? (
										<>
											<a className="btn btn-primary">
												Connected with {shortAccount(blockchainInfo.account)}
											</a>
										</>
									) : (
										<button className="btn btn-primary" onClick={connect}>
											Wallet
										</button>
									)}
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainNavbar;
