import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import {
	useListingState,
	useTokenState,
	useCollectionState,
	useCurrencyState,
	useErrorState,
	useSettingState,
	useCategoryState,
	useGlobalState,
	useSessionState,
} from "src/state";
import { Box, Button, CircularProgress, Grid, Typography, Tooltip } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import OutboundIcon from "@material-ui/icons/Outbound";
import {
	contractLinkURL,
	networkName,
	shortAccount,
	tokenLinkURL,
	transactionLinkURL,
} from "src/helpers/blockchain.helper";
import Loading from "src/components/Loading";
import "./css/listing.css";
import moment from "moment";
import { round } from "src/helpers/number.helper";

import { BlockchainContext } from "src/providers/BlockchainProvider";
import {
	approveFunds,
	buyToken,
	placeBid,
	gotSufficientBalance,
	pickAuctionWinner,
	cancelAuction,
	withdrawBid,
	gotWithdrawableBalance,
} from "src/plugins/Ethereum";
import { updateCollectionToken } from "src/db/firebase/collections";
import ActivityBlock from "src/components/frontend/partials/ActivityBlock";
import ListingViewOnBlock from "src/components/frontend/partials/ListingViewOnBlock";
import { AddToWalletIcon, ViewIPFSMetaIcon, ViewOnChainIcon, ViewOnIFPSIcon } from "src/components/frontend/icons";
import BidForm from "src/components/frontend/modals/BidForm";
import Modal from "src/components/Modal";
import TokenAttribute from "src/components/collection/TokenAttribute";
import { cachedVersion } from "src/helpers/file.helper";
import { Link } from "react-router-dom";

export const ListingImageBlock = ({ token, metadata }) => {
	return (
		<>
			<Box className="item-image">
				{token.animationUrl && token.animationFileType === "video" ? (
					<video width="100%" height="100%" autoPlay loop muted controls>
						<source src={cachedVersion(token.animationUrl, "video", 600)} type="video/mp4" />
					</video>
				) : (
					<img src={token.animationUrl ? cachedVersion(token.animationUrl) : cachedVersion(token.imageUrl)} />
				)}
			</Box>
			{metadata && metadata.gallery && metadata.gallery.length > 0 && (
				<Box mb={3} className="image-gallery">
					<Grid container>
						{metadata.gallery.map((image, index) => (
							<Grid item key={index} xs={6} md={3}>
								<Box className="item-image" m={1}>
									<a href={cachedVersion(image.imageURL, "image", 600)} data-lightbox="example-1">
										<img src={cachedVersion(image.imageURL, "image", 600)} />
									</a>
								</Box>
							</Grid>
						))}
					</Grid>
				</Box>
			)}
		</>
	);
};

const Listing = () => {
	const { cid: collectionID, id: tokenID } = useParams();
	const blockchainInfo = useContext(BlockchainContext);

	const {
		promised: isListingLoading,
		getListing,
		getListingByTokenID,
		getListingIDForToken,
		updateListing,
		addListingOffer,
	} = useListingState();

	const { promised: isCategoriesLoading, getCategory, categories } = useCategoryState();

	const { promised: isTokenLoading, getToken } = useTokenState(collectionID);
	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { getMarketplaceAddress, getSetting } = useSettingState();
	const { profile } = useSessionState();

	const { setErrorMessage } = useErrorState();

	const location = useLocation();
	const { setRedirectToAfterConnect } = useGlobalState();

	const token = getToken(collectionID, tokenID);
	const navigate = useNavigate();

	const collection = getCollection(collectionID);

	const marketplaceAddress = blockchainInfo ? getMarketplaceAddress(blockchainInfo.networkId) : null;

	const { getExRateInUSD } = useCurrencyState();

	const [isInProgress, setIsInProgress] = useState(false);
	const [metadata, setMetadata] = useState(null);
	const [showBidForm, setShowBidForm] = useState(false);

	const connect = () => {
		//save the current URL to state
		//to redirect user back to this page
		setRedirectToAfterConnect(location.pathname);

		if (!blockchainInfo.account) {
			navigate("/connect-wallet#buy");
			return;
		}

		navigate("/profile/settings");
	};

	const handleBuyNow = async () => {
		if (isInProgress) return;

		setIsInProgress(true);

		let check = null;
		try {
			check = await gotSufficientBalance(minBidAmount + minIncrement, listing.currency, collection.chainID);

			if (!check) {
				setErrorMessage(
					`you do not have sufficient balance in your wallet. Please add in atleast ${listing.amount} ${listing.currency}`
				);
				setIsInProgress(false);
				return;
			}

			//get buyer's approval to deduct the currency from wallet ballance
			const approved = await approveFunds(
				marketplaceAddress,
				listing.amount,
				listing.currency,
				collection.chainID
			);

			//const approved = true;

			if (approved) {
				//trigger payment
				buyToken({
					contractType: collection.contract_type,
					contractAddress: collection.address,
					tokenID: listing.tokenID,
					amount: listing.amount,
					currency: listing.currency,
					marketplaceAddress,
					royaltyPercentage: collection.royalty,
					networkID: collection.chainID,
				})
					.then(transactionHash => {
						markListingAsPurchased(transactionHash, blockchainInfo.account);
					})
					.catch(e => {
						console.log(e);
						setErrorMessage(`Sorry! Unable to complete transaction, please try again`);
					})
					.finally(() => {
						setIsInProgress(false);
					});
			} else {
				setErrorMessage(`Sorry! Unable to process this transaction at this moment. Please try again later.`);
				setIsInProgress(false);
			}
		} catch (e) {
			console.log(e);

			setErrorMessage(`Sorry! Unable to process this transaction at this moment. Please try again later.`);
			setIsInProgress(false);
		}
	};

	const handlePlaceBid = async () => {
		if (isInProgress) return;

		setIsInProgress(true);

		let check = null;
		try {
			check = await gotSufficientBalance(minBidAmount + minIncrement, listing.currency, collection.chainID);
		} catch (e) {
			console.log(e);
		}

		if (!check) {
			setErrorMessage(
				`you do not have sufficient balance in your wallet. Please add in atleast ${
					listing.amount + minIncrement
				} ${listing.currency}`
			);
			setIsInProgress(false);
			return;
		}

		setShowBidForm(true);
	};

	const handleWithdrawBid = async () => {
		if (isInProgress) return;

		setIsInProgress(true);
		let check = null;
		try {
			check = await gotWithdrawableBalance({ auctionAddress: listing.auctionAddress });
		} catch (e) {
			console.log(e);
		}

		if (!check) {
			setErrorMessage(
				`You do not have any balance to withdraw. Please feel free to contact us if you are facing any issue.`
			);
			setIsInProgress(false);
			return;
		}

		try {
			await withdrawBid({ auctionAddress: listing.auctionAddress });
			setErrorMessage(`You have withdrawn your bid successfully!`, "success");
		} catch (e) {
			console.log(e);
		}
		setIsInProgress(false);
	};

	const handlePickWinner = async () => {
		setIsInProgress(true);
		try {
			pickAuctionWinner({
				auctionAddress: listing.auctionAddress,
				networkID: collection.chainID,
			})
				.then(({ transactionHash, winnerAddress }) => {
					markListingAsPurchased(transactionHash, winnerAddress);
				})
				.catch(error => {
					setErrorMessage(`Sorry! Transaction has been rejected. Please try again`);
				})
				.finally(() => {
					setIsInProgress(false);
				});
		} catch (e) {
			console.log(e);
		}
	};

	const handleCancelAuction = () => {
		setIsInProgress(true);
		try {
			cancelAuction({
				auctionAddress: listing.auctionAddress,
				isWithNativeCurrency: listing.isWithNativeCurrency,
			})
				.then(({ transactionHash }) => {
					updateListing(listing.listingID, {
						cancelled: true,
						cancelledOn: new Date().getTime(),
						cancelledTx: transactionHash,
					});
				})
				.catch(error => {
					setErrorMessage(`Sorry! Transaction has been rejected. Please try again`);
				})
				.finally(() => {
					setIsInProgress(false);
				});
		} catch (e) {
			console.log(e);
		}
	};

	const processBid = async ({ amount }) => {
		setShowBidForm(false);

		if (!amount || amount < minBidAmount) {
			setErrorMessage("Please select a valid bid amount to place your Bid");
			setIsInProgress(false);
			return;
		}

		setIsInProgress(true);

		try {
			let approved = false;
			if (listing.isWithNativeCurrency) {
				approved = true;
			} else {
				//get buyer's approval to deduct the currency from wallet ballance
				approved = await approveFunds(listing.auctionAddress, amount, listing.currency, collection.chainID);
			}

			if (approved) {
				placeBid({
					auctionAddress: listing.auctionAddress,
					amount: amount,
					currency: listing.currency,
					networkID: collection.chainID,
				})
					.then(transactionHash => {
						markListingOffer(transactionHash, { amount });
					})
					.catch(e => {
						console.log(e);
						setErrorMessage(`Sorry! Unable to complete transaction, please try again`);
					})
					.finally(() => {
						setIsInProgress(false);
					});
			} else {
				setErrorMessage(`Sorry! Unable to process this transaction at this moment. Please try again later.`);
				setIsInProgress(false);
			}
		} catch (err) {
			setIsInProgress(false);
		}
	};

	const markListingAsPurchased = (transactionHash, buyerAddress) => {
		updateListing(listing.listingID, {
			purchased: true,
			purchasedBy: buyerAddress,
			purchasedOn: new Date().getTime(),
			purchaseTx: transactionHash,
		});

		updateCollectionToken(collectionID, token.id, {
			owner: buyerAddress,
		});
	};

	const markListingOffer = (transactionHash, offer) => {
		// add this offer to collection under the listing
		const offerData = {
			amount: offer.amount,
			currency: listing.currency,
			from: blockchainInfo.account,
			tx: transactionHash,
		};
		addListingOffer(listing.listingID, offerData);

		// update the highest bid value
		updateListing(listing.listingID, {
			highest_bid: offer.amount,
		});
	};

	const handleModalClose = () => {
		showBidForm && setShowBidForm(false);
		isInProgress && setIsInProgress(false);
	};

	useEffect(() => {
		if (token && token.metadataUrl && !metadata) {
			fetch(token.metadataUrl)
				.then(response => response.json())
				.then(data => {
					if (data) {
						setMetadata(data);
						//ToDo: save to db
					} else setMetadata([]);
				});
		}
	}, [token, metadata]);

	if (!token || !collection) {
		navigate("/404", { replace: true });
		return null;
	}

	const previousListings = getListingByTokenID(collectionID, tokenID);
	const listingID = getListingIDForToken(collectionID, tokenID);
	const listing = listingID ? getListing(listingID) : {};

	const listingCategory = collection.categoryID && !isCategoriesLoading ? getCategory(collection.categoryID) : null;

	const attributesAsBlocks = [
		"project_name",
		"project_tagline",
		"project_country",
		"project_address",
		"sales_return",
		"owner_name",
		"capital_request",
		"registration_cert_updated",
		//"licenses",
		"tax_license_no",
		"distribution_partners",
		"prove_assets_registration",
		"target_irr",
	];

	const minBidAmount = listing
		? listing.highest_bid
			? Math.round(listing.highest_bid * 100) / 100
			: listing.amount
		: 0;

	const minIncrement = listing?.highest_bid ? (listing.isWithNativeCurrency ? 0.01 : 0.01) : 0;

	const ExternalLinkButtons = () => (
		<div className="css-vurnku">
			<div className="css-1hhedd7">
				{token.certificateUrl && (
					<ListingViewOnBlock
						linkTo={token.certificateUrl}
						title="View Certificate on IPFS"
						icon={ViewOnIFPSIcon}
					/>
				)}
				{token.descriptionUrl && (
					<ListingViewOnBlock
						linkTo={token.descriptionUrl}
						title="View Description on IPFS"
						icon={ViewIPFSMetaIcon}
					/>
				)}
				<ListingViewOnBlock
					linkTo={tokenLinkURL(collection.chainID, collectionID, tokenID)}
					title={`View on ${networkName(collection.chainID)}`}
					icon={ViewOnChainIcon}
				/>
				<ListingViewOnBlock linkTo={token.imageIPFSUrl} title="View on IPFS" icon={ViewOnIFPSIcon} />
				<ListingViewOnBlock linkTo={token.metadataUrl} title="View IPFS Metadata" icon={ViewIPFSMetaIcon} />
				<ListingViewOnBlock
					title="Add to your Wallet"
					icon={AddToWalletIcon}
					onClick={async () => {
						try {
							// wasAdded is a boolean. Like any RPC method, an error may be thrown.
							const wasAdded = await window.ethereum.request({
								method: "wallet_watchAsset",
								params: {
									type: "ERC20", // Initially only supports ERC20, but eventually more!
									options: {
										address: collection.address, // The address that the token is at.
										symbol: collection.symbol, // A ticker symbol or shorthand, up to 5 chars.
										decimals: 0, // The number of decimals in the token
										image: "", // A string url of the token logo
									},
								},
							});

							if (wasAdded) {
								console.log("Thanks for your interest!");
							} else {
								console.log("Your loss!");
							}
						} catch (error) {
							console.log(error);
						}
					}}
				/>
			</div>
		</div>
	);

	return (
		<>
			<div className="page-title">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-6">
							<div className="page-title-content">
								<h3>Listing Details</h3>
							</div>
						</div>
						<div className="col-auto">
							<div className="breadcrumbs">
								<a href="https://pbw2023.metaveblen.org">Home</a>
								<span>
									<i className="ri-arrow-right-s-line"></i>
								</span>
								<Link to={`/collection/${collection.address}`}>{collection.name}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isListingLoading || isTokenLoading || isCollectionLoading ? (
				<Loading />
			) : (
				<div className="explore-details section-padding">
					<div className="container">
						<div className="row justify-content-between">
							<div className="col-xl-4">
								<div className="explore-details-head">
									<h2>
										{collection.name} ({token.itemName})
									</h2>
									<h6>
										ERC{collection.contractType} NFT minted in{" "}
										{moment(collection.createdAt).format("MMM YYYY")}
										<br /> on {networkName(collection.chainID)}
									</h6>

									<div className="css-4cffwv" style={{ margin: "30px 0" }}>
										<div className="css-vurnku">
											<div className="css-1jm49l2" aria-expanded="false">
												<a
													className="css-h6hd16"
													href={tokenLinkURL(collection.chainID, collection.owner)}
													target="_blank"
												>
													<div className="css-1fp3cxy">
														<div className="css-4cffwv">
															<div className="username-tag css-5uuy7">
																{shortAccount(collection.owner)}
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="d-none d-lg-block">
									<ExternalLinkButtons />
								</div>
								{listing && (listing.listingType === "sale" || listing.listingType === "auction") && (
									<div className="card">
										<div className="card-body">
											<div className="explore-details-content">
												{listing && !listing.purchased && listing.listingType === "sale" && (
													<div>
														<div>
															<div className="css-1sqg0uu">
																<div className="css-wbxa2r">Listing Price</div>
																<div className="css-rivphl">
																	{listing.amount} {listing.currency}{" "}
																	<span className="css-unu6y4">
																		($
																		{round(
																			listing.amount *
																				getExRateInUSD(listing.currency)
																		)}
																		)
																	</span>
																</div>
															</div>
															{listing.endDate && (
																<div className="css-vurnku">
																	<div className="css-wbxa2r">
																		Listing{" "}
																		{moment(listing.endDate).isAfter()
																			? "ending"
																			: "expired"}{" "}
																		on
																	</div>
																	<div className="css-1minwi3">
																		<div className="css-vurnku">
																			<div className="css-rivphl">
																				{moment(listing.endDate).format(
																					"DD MMM, YYYY"
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															)}
														</div>
														{blockchainInfo.account === token.owner ? (
															moment(listing.endDate).isAfter() ? (
																<div className="css-3rf65c">
																	Your sale listing is active
																</div>
															) : (
																<div className="css-3rf65c">
																	Your sale listing has expired
																</div>
															)
														) : moment(listing.endDate).isAfter() ? (
															<div className="css-3rf65c">
																{!blockchainInfo.account ||
																profile.username === "" ||
																collection.chainID === blockchainInfo.networkId ? (
																	<button
																		className="btn btn-primary w-100"
																		onClick={
																			blockchainInfo.account &&
																			profile.username !== ""
																				? handleBuyNow
																				: connect
																		}
																	>
																		{isInProgress ? (
																			<CircularProgress
																				size={20}
																				color="inherit"
																			/>
																		) : (
																			"Buy Now"
																		)}
																	</button>
																) : (
																	`You are connected with wrong Blockchain. Please switch to ${networkName(
																		collection.chainID
																	)} in order to buy this NFT`
																)}
															</div>
														) : null}
													</div>
												)}
												{listing && listing.listingType === "auction" && (
													<div>
														<div>
															{listing.endDate && (
																<div className="css-vurnku">
																	<div className="css-wbxa2r">
																		<AccessTimeIcon
																			sx={{
																				verticalAlign: "middle",
																			}}
																		/>{" "}
																		Auction{" "}
																		{listing.cancelled ? (
																			"Cancelled"
																		) : (
																			<>
																				{moment().diff(
																					moment(
																						listing.endDate + " 22:59:59"
																					)
																				) > 0
																					? "ended at"
																					: "ends"}{" "}
																				{moment(listing.endDate).format(
																					"DD MMM, YYYY"
																				)}{" "}
																				10:59pm
																			</>
																		)}
																	</div>
																	<div className="css-1minwi3">
																		<div className="css-vurnku">
																			<div className="css-rivphl"></div>
																		</div>
																	</div>
																</div>
															)}
															<div className="css-1sqg0uu">
																<div className="css-wbxa2r">Top bid</div>
																<div className="css-rivphl">
																	{listing.currency} {minBidAmount}{" "}
																	<span className="css-unu6y4">
																		($
																		{round(
																			minBidAmount *
																				getExRateInUSD(listing.currency)
																		)}
																		)
																	</span>
																	{!listing.cancelled && (
																		<Tooltip
																			title="The highest bidder will win the item at the end of the auction."
																			placement="top"
																		>
																			<OutboundIcon
																				sx={{
																					verticalAlign: "middle",
																					ml: 1,
																				}}
																			/>
																		</Tooltip>
																	)}
																</div>
															</div>
														</div>
														{blockchainInfo.networkId &&
														collection.chainID === blockchainInfo.networkId ? (
															blockchainInfo.account !== token.owner ? (
																<div className="css-3rf65c">
																	{(listing.cancelled || listing.purchased) &&
																	listing.isWithNativeCurrency ? (
																		<Button
																			variant="contained"
																			color="primary"
																			className="css-6iow5d"
																			onClick={handleWithdrawBid}
																		>
																			{isInProgress ? (
																				<CircularProgress
																					size={20}
																					color="inherit"
																				/>
																			) : (
																				<>
																					<AccountBalanceWalletOutlinedIcon />
																					<Typography variant="h5" ml={1}>
																						Withdraw Bid
																					</Typography>
																				</>
																			)}
																		</Button>
																	) : (
																		<>
																			{moment().diff(
																				moment(listing.endDate + " 22:59:59")
																			) < 0 ? (
																				<Button
																					variant="contained"
																					color="primary"
																					className="css-6iow5d"
																					onClick={
																						blockchainInfo.account &&
																						profile.username !== ""
																							? handlePlaceBid
																							: connect
																					}
																				>
																					{isInProgress ? (
																						<CircularProgress
																							size={20}
																							color="inherit"
																						/>
																					) : (
																						<>
																							<AccountBalanceWalletOutlinedIcon />
																							<Typography
																								variant="h5"
																								ml={1}
																							>
																								Place Bid
																							</Typography>
																						</>
																					)}
																				</Button>
																			) : (
																				<Typography
																					sx={{
																						textAlign: "center",
																					}}
																				>
																					** Bidding period ended **
																				</Typography>
																			)}
																		</>
																	)}
																</div>
															) : blockchainInfo.account === listing.creator ? (
																<div className="css-3rf65c">
																	{moment().diff(
																		moment(listing.endDate + " 22:59:59")
																	) >= 0 ? (
																		<Button
																			variant="contained"
																			color="primary"
																			className="css-6iow5d"
																			onClick={handlePickWinner}
																		>
																			{isInProgress ? (
																				<CircularProgress
																					size={20}
																					color="inherit"
																				/>
																			) : (
																				<Typography variant="h5" ml={1}>
																					Approve Winner
																				</Typography>
																			)}
																		</Button>
																	) : (
																		<>
																			<Typography
																				sx={{
																					textAlign: "center",
																				}}
																			>
																				** Your Auction{" "}
																				{listing.cancelled
																					? "has been cancelled"
																					: "is in progress"}
																				. **
																			</Typography>
																			{listing.isWithNativeCurrency &&
																				!listing.cancelled && (
																					<Button
																						variant="contained"
																						color="error"
																						className="css-6iow5d"
																						onClick={handleCancelAuction}
																					>
																						{isInProgress ? (
																							<CircularProgress
																								size={20}
																								color="inherit"
																							/>
																						) : (
																							<Typography
																								variant="h5"
																								ml={1}
																							>
																								Cancel Auction
																							</Typography>
																						)}
																					</Button>
																				)}
																		</>
																	)}
																</div>
															) : null
														) : (
															<div className="css-3rf65c">
																{blockchainInfo.networkId ? (
																	<>
																		{`You are connected with wrong Blockchain. Please switch to ${networkName(
																			collection.chainID
																		)} in order to place your bid`}
																	</>
																) : (
																	<>
																		<Button
																			variant="contained"
																			color="primary"
																			className="css-6iow5d"
																			onClick={connect}
																		>
																			{isInProgress ? (
																				<CircularProgress
																					size={20}
																					color="inherit"
																				/>
																			) : (
																				<>
																					<AccountBalanceWalletOutlinedIcon />
																					<Typography variant="h5" ml={1}>
																						Connect to your Wallet
																					</Typography>
																				</>
																			)}
																		</Button>
																		{(listing.cancelled || listing.purchased) && (
																			<Typography
																				variant="caption"
																				align="center"
																			>
																				If you had bid for this auction, you can
																				connect your wallet to withdraw your
																				full bid
																			</Typography>
																		)}
																	</>
																)}
															</div>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="col-xl-8">
								<div className="card">
									<div className="explorer-details-slider">
										<ListingImageBlock token={token} metadata={metadata} />
									</div>
									<div className="card-body">
										<div className="explore-details-about">
											{collection.description && (
												<p style={{ whiteSpace: "pre-line" }}>{collection.description}</p>
											)}
											{metadata && metadata.description && (
												<p style={{ whiteSpace: "pre-line" }}>{metadata.description}</p>
											)}
											{/* <div className="social-link">
												<Link to="#">
													<a>
														<i className="bi bi-tiktok"></i>
													</a>
												</Link>
												<Link to="#">
													<a>
														<i className="bi bi-telegram"></i>
													</a>
												</Link>
												<Link to="#">
													<a>
														<i className="bi bi-discord"></i>
													</a>
												</Link>
											</div> */}
										</div>
									</div>
								</div>
								{metadata && metadata.attributes && metadata.attributes.length > 0 && (
									<>
										<div className="card-header px-0">
											<h4 className="card-title">Properties</h4>
										</div>
										<div className="card">
											<div className="card-body">
												<div className="pool-details">
													<ul>
														{metadata.attributes
															.filter(
																item =>
																	process.env.REACT_APP_SITENAME !== "FoodStarter" ||
																	attributesAsBlocks.includes(item.key)
															)
															.map((attribute, index) =>
																attribute.trait_type &&
																attribute.trait_type !== "Item Code" &&
																attribute.value != "" ? (
																	<li key={index} item xs={6} sm={4} md={3}>
																		<span>{attribute.trait_type} : </span>{" "}
																		{attribute.value}
																	</li>
																) : null
															)}
													</ul>
												</div>
											</div>
										</div>
									</>
								)}

								<div className="d-block d-lg-none">
									<ExternalLinkButtons />
								</div>

								<div className="css-1glq2si">
									<div className="card-header px-0 pb-0">
										<h4 className="card-title">Activity</h4>
									</div>
									<div className="css-1zj86l">
										{listing && listing.purchased && (
											<ActivityBlock
												title="Purchased by"
												address={shortAccount(listing.purchasedBy)}
												addressLink={contractLinkURL(collection.chainID, listing.purchasedBy)}
												timestamp={moment(listing.purchasedOn).format(
													"MMM Do YYYY [at] h:mm a"
												)}
												amount={
													listing.listingType === "auction"
														? listing.highest_bid
														: listing.amount
												}
												currency={listing.currency}
												blockchainLink={transactionLinkURL(
													collection.chainID,
													listing.purchaseTx
												)}
											/>
										)}

										{listing &&
											listing.listingType === "auction" &&
											listing.offers &&
											[...listing.offers]
												.reverse()
												.map((offer, idx) => (
													<ActivityBlock
														key={idx}
														title={`Offer by`}
														address={shortAccount(offer.from)}
														addressLink={contractLinkURL(collection.chainID, offer.from)}
														timestamp={moment(offer.createdAt).format(
															"MMM Do YYYY [at] h:mm a"
														)}
														amount={offer.amount}
														currency={listing.currency}
														blockchainLink={
															offer.tx
																? transactionLinkURL(collection.chainID, offer.tx)
																: null
														}
													/>
												))}

										{listing && listing.listingType && (
											<ActivityBlock
												title={`Listed for ${
													listing.listingType === "sale" ? "Sale" : "Auction"
												} by`}
												address={shortAccount(listing.creator) || " Owner"}
												addressLink={contractLinkURL(collection.chainID, listing.creator)}
												timestamp={moment(listing.createdAt).format("MMM Do YYYY [at] h:mm a")}
												amount={listing.amount}
												currency={listing.currency}
												blockchainLink={
													listing.creationTx
														? transactionLinkURL(collection.chainID, listing.creationTx)
														: null
												}
											/>
										)}

										{previousListings
											.filter(item => !listing || item.listingID !== listingID)
											.map((prevListing, index) => (
												<React.Fragment key={index}>
													<ActivityBlock
														title={
															prevListing.listingType === "auction"
																? prevListing.cancelled
																	? "Cancelled by"
																	: "Won in Auction by"
																: "Purchased by"
														}
														address={shortAccount(
															prevListing.cancelled
																? prevListing.creator
																: prevListing.purchasedBy
														)}
														addressLink={contractLinkURL(
															collection.chainID,
															prevListing.cancelled
																? prevListing.creator
																: prevListing.purchasedBy
														)}
														timestamp={moment(
															prevListing.cancelled
																? prevListing.cancelledOn
																: prevListing.purchasedOn
														).format("MMM Do YYYY [at] h:mm a")}
														amount={
															prevListing.listingType === "auction"
																? prevListing.highest_bid
																: prevListing.amount
														}
														currency={prevListing.currency}
														blockchainLink={transactionLinkURL(
															collection.chainID,
															prevListing.cancelled
																? prevListing.cancelledTx
																: prevListing.purchaseTx
														)}
													/>
													<ActivityBlock
														title={`Listed for ${
															prevListing.listingType === "sale" ? "Sale" : "Auction"
														} by`}
														address={shortAccount(prevListing.creator) || " Owner"}
														addressLink={contractLinkURL(
															collection.chainID,
															prevListing.creator
														)}
														timestamp={moment(prevListing.createdAt).format(
															"MMM Do YYYY [at] h:mm a"
														)}
														amount={prevListing.amount}
														currency={prevListing.currency}
													/>
												</React.Fragment>
											))}

										<ActivityBlock
											title="Minted by"
											address={shortAccount(token.minter)}
											addressLink={contractLinkURL(collection.chainID, token.minter)}
											timestamp={moment(token.createdAt).format("MMM Do YYYY [at] h:mm a")}
											blockchainLink={tokenLinkURL(collection.chainID, collectionID, tokenID)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<Modal
				open={showBidForm}
				onClose={handleModalClose}
				content={
					<BidForm
						minAmount={Math.round((minBidAmount + minIncrement) * 100) / 100}
						currency={listing.currency}
						onPlaceBid={processBid}
					/>
				}
			/>
		</>
	);
};

export default Listing;
