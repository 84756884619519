import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSettingState } from "src/state";

const MainFooter = () => {
	const { promised: isSettingsLoading, getSetting } = useSettingState();

	return isSettingsLoading ? null : (
		<>
			<div className="bottom section-padding triangle-top-dark triangle-bottom-dark">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-7 col-sm-8">
							<div className="bottom-logo">
								<img
									className="pb-3"
									src="/assets/images/logo.png"
									alt=""
									style={{ maxWidth: "60%" }}
								/>
								<p>MetaVeblen is a unique and beautiful collection of collectibles.</p>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-md-5 col-sm-4 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title">About us</h4>
								<ul>
									<li>
										<Link to="/about#veblenian">
											Veblenian
										</Link>
									</li>
									<li>
										<Link to="/about#governance">
											Governance
										</Link>
									</li>
									<li>
										<Link to="/about#show">
											V Show
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title">&nbsp;</h4>
								<ul>
									<li>
										<Link to="/about#mvpay">
											MVPay
										</Link>
									</li>
									<li>
										<Link to="/about#privilege">
											Privilege
										</Link>
									</li>
									<li>
										<Link to="/about#journey">
											MV Journey
										</Link>
									</li>
								</ul>
							</div>
						</div>
						{/* <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
							<div className="bottom-widget">
								<h4 className="widget-title">Profile</h4>
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
										<ul>
											<li>
												<Link to="/profile">
													<a>Profile</a>
												</Link>
											</li>
											<li>
												<Link to="/created">
													<a>Created</a>
												</Link>
											</li>
											<li>
												<Link to="/collected">
													<a>Collected</a>
												</Link>
											</li>
											<li>
												<Link to="/activity">
													<a>Activity</a>
												</Link>
											</li>
										</ul>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
										<ul>
											<li>
												<Link to="/onsale">
													<a>On Sale</a>
												</Link>
											</li>
											<li>
												<Link to="/liked">
													<a>Liked</a>
												</Link>
											</li>
											<li>
												<Link to="/following">
													<a>Following</a>
												</Link>
											</li>
											<li>
												<Link to="/followers">
													<a>Followers</a>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className="footer">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="copyright">
								<p>
									© Copyright 2022 MetaVeblen All Rights Reserved
								</p>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="footer-social">
								<ul>
									<li>
										<a href="#">
											<i className="bi bi-facebook"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="bi bi-twitter"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="bi bi-linkedin"></i>
										</a>
									</li>
									<li>
										<a href="#">
											<i className="bi bi-youtube"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MainFooter;
